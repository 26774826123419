/*********************************************/
/*          CUSTOMIZED DEFAULTS         */
/*********************************************/
.chatbot-widget {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999999 !important;
}

.chatbot-tab {
  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 200px;
  height: 40px;
  background-color: #333;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999999 !important;
}

.chatbot-tab:hover {
  opacity: 0.8;
}

.chatbot-tab h2 {
  margin: 0;
  font-size: 16px;
}

.hide-button {
  position: absolute;
  top: -40px;
  right: 10px;
}

.clear-chat-button {
  position: absolute;
  top: -40px;
  right: 80px;
  white-space: nowrap; /* Ensures the text doesn't wrap */
  width: auto; /* Allows the button's width to adjust based on text length */
}
.nav-link {
  color: white !important;
  font-size: 20px !important;
}

.dropdown-toggle {
  font-size: 20px !important;
}

.dropdown-toggle-2 {
  white-space: nowrap;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.dropdown-toggle-2::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle-2:empty::after {
  margin-left: 0;
}

.set-unit-button-style {
  padding: 0.13rem 0.5rem !important;
  font-size: 0.875rem !important;
  /* Add any other desired styles */
}

.brand-image {
  max-height: 113px;
  width: auto;
}

.card {
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.background-one {
  background-color: #333;
  height: 300px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.recipe-image {
  max-width: 100%;
  height: auto;
}

.recipe-image-75 {
  max-width: 75%;
  height: auto;
}

.card-img-top-center {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* Create a second div element that sits beneath the first one */
.background-two {
  background-color: #f5f5f5;
  height: calc(
    100% - 200px
  ); /* Set the height of this div to take up the remaining height of the page, minus 200px */
  margin-top: 200px; /* Set the top margin to 200px to ensure this div sits beneath the first one */
  z-index: -1;
}

.color-swatch {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.swatch-red {
  background-color: red;
}

.swatch-blue {
  background-color: blue;
}

/* .swatch-green {
  background-color: green;
} */

.swatch-1 {
  background-color: #98f5e1;
}

.swatch-2 {
  background-color: #fbf8cc;
}

.swatch-3 {
  background-color: #fde4cf;
}

.swatch-4 {
  background-color: #90dbf4;
}

.swatch-5 {
  background-color: #f1c0e8;
}

.swatch-6 {
  background-color: #8eecf5;
}

.swatch-7 {
  background-color: #cfbaf0;
}

.swatch-8 {
  background-color: #b9fbc0;
}

.swatch-9 {
  background-color: #ffcfd2;
}

.swatch-10 {
  background-color: #a3c4f3;
}

.my-button {
  padding: 2px 8px !important; /* Change the padding values as needed */
}

.col-sm-1-5 {
  @media (min-width: 576px) {
    width: calc(100% / 5) !important;
  }
}

/* Target the accordion button by its class and ID */
.accordion-button {
  font-weight: bold !important;
  color: black !important;
  background-color: rgb(204, 204, 204) !important;
}

.accordion-button:not(.collapsed) {
  /* Update the background color */
  font-weight: bold !important;
  color: black !important;
  background-color: rgb(204, 204, 204) !important;
}

.input#file-upload-button {
  background-color: #0d6efd !important;
  color: #fff;
  border-color: #0d6efd;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  background-color: #0d6efd !important;
  color: #fff;
  border-color: #0d6efd;
  padding: 0.43rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #0b5ed7 !important;
}

.navbar-collapse {
  background-color: #333 !important;
  border-radius: 5px;
}

.EditIngredientButton {
  color: black;
  font-weight: bold;
  text-decoration: underline;
  background: none;
  border: none;
}

$accordian-icon-color: black;

$accordion-icon-active-color: black;

$accordion-padding-y: 0.5rem;
$accordion-padding-x: 0.5rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px,
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
