.full-chatbot {
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column; /* Use flexbox for layout */
  z-index: 999999 !important; /* Make the chatbot appear on top of other elements */
}

.full-chatbot.expanded {
  width: 80%;
  height: 80%;
  bottom: 60px;
  right: 20px;
  border-radius: 2;
  z-index: 999999 !important;
}

.full-chatbot.hidden {
  width: 5px;
  height: 80%;
  bottom: 60px;
  right: -250px;
  border-radius: 900px !important;
  z-index: 999999 !important;
  position: fixed;
}

.chat-header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.chat-body {
  max-width: 2500px;
  word-wrap: break-word;
  margin-bottom: 10px;
  line-height: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column; /* stack messages vertically */
  max-height: 100%;
  overflow-y: auto;
}

.user-message {
  position: relative;
  font-size: 16px;
  padding: 8px 8px;
  color: white;
  background: #0b93f6;
  border-radius: 25px;
  align-self: flex-end; /* position message to the right */
  margin-bottom: 5px;
  margin-left: 50px;
}

.user-message p {
  margin: 0 0 0 0; /* sets margin-top to 5px */
}

.bot-message1 {
  position: relative;
  padding: 8px 8px;
  background: #e5e5ea;
  border-radius: 25px;
  color: black;
  align-self: flex-start; /* position message to the left */
  margin-right: 50px;
  margin-bottom: 5px;
}

.bot-message {
  position: relative;
  padding: 8px 8px;
  background: #e5e5ea;
  border-radius: 25px;
  color: black;
  align-self: flex-start; /* position message to the left */
  margin-bottom: 5px;
  margin-right: 50px;
}

.bot-message p {
  margin: 0 0 0 0; /* sets margin-top to 5px */
}

.chat-footer {
  display: flex;
  flex-shrink: 0; /* Use flexbox to prevent the footer from shrinking */
  padding: 10px;
}

.chat-footer > input {
  flex: 1; /* Use flexbox to make the input area take up remaining space */
  padding: 10px;
  border: none;
  border-top: 1px solid #e0e0e0;
  font-size: 16px;
  outline: none;
}

.chat-bubble {
  display: flex;
  align-items: center;
}

.chat-bubble .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #007aff; /* Change the color to fit your design */
  margin-right: 5px;
}

.chat-bubble .dot:nth-of-type(1) {
  animation: move1 1s ease-in-out infinite;
}

@keyframes move1 {
  0%,
  100% {
    opacity: 0.2;
    transform: translate(0, 1px);
  }
  16.67%,
  83.33% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.chat-bubble .dot:nth-of-type(2) {
  animation: move2 1s ease-in-out infinite;
}

@keyframes move2 {
  0%,
  100% {
    opacity: 0.2;
    transform: translate(0, 1px);
  }
  33.33%,
  66.67% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.chat-bubble .dot:nth-of-type(3) {
  animation: move3 1s ease-in-out infinite;
}

@keyframes move3 {
  0%,
  100% {
    opacity: 0.2;
    transform: translate(0, 1px);
  }
  50% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.notification {

  background-color: #ff0000; /* Red color for error notification */
  color: #ffffff; /* White text color */
  padding: 15px; /* Increase padding for better appearance */
  border-radius: 20px; /* Rounded edges */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
  animation: fadeInOut 5s ease-in-out;
  text-align: center; /* Center text horizontally */
  display: flex; /* Use flexbox for vertical centering */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* In your CSS file
.form-control-chatbot {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  width: 100%; 
}

.form-control-chatbot:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
} */

.form-control-chatbot {
  padding: 2px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%; /* Adjust based on your layout */
  max-width: 100%; /* Maximum width of textarea */
  max-height: 80px; /* Maximum height of textarea */
  overflow-y: auto; /* Enable scrollbar when exceeding max-height */
  resize: none; /* Disable textarea resizing */
  font-size: 14px;
} 

.form-control-chatbot:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.chat-container {
  display: flex;
  align-items: flex-end; /* Align items to bottom */
  width: 100%; /* Ensure the container occupies the full width */
}